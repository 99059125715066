<template>
  <div>
    <ul class="menu-nav" v-if="currentUser.role != 6">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-architecture-and-city"></i>
            <span class="menu-text">Dashboard</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Shipments</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/prealert/add-shipment-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-add-1"></i>
            <span class="menu-text">Add Shipment</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/prealert/prealert-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-cube"></i>
            <span class="menu-text">Pre-alert</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/prealert/shipments-admin"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-cube"></i>
            <span class="menu-text">Shipments</span>
          </a>
        </li>
      </router-link>

      <li class="menu-section">
        <h4 class="menu-text">Organization Staff</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/users/users"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-open-box"></i>
            <span class="menu-text">Users</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/consigne/all"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-open-box"></i>
            <span class="menu-text">Consignees</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Customers</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/users/customers"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-analytics"></i>
            <span class="menu-text">Customers</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Containers</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/container/add"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-squares-4"></i>
            <span class="menu-text">Add Container</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/container/all"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-open-box"></i>
            <span class="menu-text">Manage Containers</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Fleet Management</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/drivers/all"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-car"></i>
            <span class="menu-text">Manage Drivers</span>
          </a>
        </li>
      </router-link>

      <li class="menu-section">
        <h4 class="menu-text">Rates Management</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/shipping/zone"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipping Zone</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/shipping/rate"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipping Rate</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/shipping/currency"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Currency Conversion</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/vat"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">GST/Vat</span>
          </a>
        </li>
      </router-link>

      <li class="menu-section">
        <h4 class="menu-text">Products Settings</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/settings/product-description"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Product Description</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/weight-scale"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Weight Scale</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/size-scale"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Size Scale</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/currency"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Currency</span>
          </a>
        </li>
      </router-link>

      <li class="menu-section">
        <h4 class="menu-text">Shipment Settings</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>

      <router-link
        to="/settings/courier"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Couriers</span>
          </a>
        </li>
      </router-link>

      <router-link
        to="/settings/shipment-payer"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipment Payer</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/shipping-mode"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipping Mode</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/shipment-type"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipment Type</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/shipment-status-list"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Shipment Status List</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/remarks-status-list"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-bullet menu-bullet-dot">
              <span></span>
            </i>
            <span class="menu-text">Remarks List</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">General Settings</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/settings') }"
      >
        <a href="#" class="menu-link menu-toggle">
          <i class="menu-icon flaticon-cogwheel"></i>
          <span class="menu-text">Settings</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text">Settings</span>
              </span>
            </li>

            <router-link
              to="/settings/branch"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Branch</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/settings/country"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Country</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/settings/state"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">States</span>
                </a>
              </li>
            </router-link>
            <router-link
              to="/settings/virtual-address"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot">
                    <span></span>
                  </i>
                  <span class="menu-text">Virtual Address</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="menu-nav" v-if="currentUser.role == 6">
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-architecture-and-city"></i>
            <span class="menu-text">Dashboard</span>
          </a>
        </li>
      </router-link>
      <li class="menu-section">
        <h4 class="menu-text">Shipments</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>
      <router-link
        to="/prealert/book-shipment"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-location"></i>
            <span class="menu-text">Book Shipment</span>
          </a>
        </li>
      </router-link>
      <!-- <router-link
        to="/prealert/add"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-plus"></i>
            <span class="menu-text">Prealert</span>
          </a>
        </li>
      </router-link> -->
      <router-link
        to="/prealert/shipments"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-cube"></i>
            <span class="menu-text">My Shipments</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/settings/address-book"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon-map-location"></i>
            <span class="menu-text">Addressbook</span>
          </a>
        </li>
      </router-link>
      <router-link
        to="/shipping/search-rate"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-supermarket"></i>
            <span class="menu-text">Shipping Rate</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
