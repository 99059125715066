var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentUser.role != 6)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}],null,false,1129774971)}),_vm._m(0),_c('router-link',{attrs:{"to":"/prealert/add-shipment-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-add-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Add Shipment")])])])]}}],null,false,3936092810)}),_c('router-link',{attrs:{"to":"/prealert/prealert-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cube"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pre-alert")])])])]}}],null,false,88190611)}),_c('router-link',{attrs:{"to":"/prealert/shipments-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cube"}),_c('span',{staticClass:"menu-text"},[_vm._v("Shipments")])])])]}}],null,false,2462385748)}),_vm._m(1),_c('router-link',{attrs:{"to":"/users/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-open-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])])])]}}],null,false,3847271802)}),_c('router-link',{attrs:{"to":"/consigne/all"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-open-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("Consignees")])])])]}}],null,false,3548685882)}),_vm._m(2),_c('router-link',{attrs:{"to":"/users/customers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-analytics"}),_c('span',{staticClass:"menu-text"},[_vm._v("Customers")])])])]}}],null,false,2511809173)}),_vm._m(3),_c('router-link',{attrs:{"to":"/container/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares-4"}),_c('span',{staticClass:"menu-text"},[_vm._v("Add Container")])])])]}}],null,false,3661937209)}),_c('router-link',{attrs:{"to":"/container/all"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-open-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Containers")])])])]}}],null,false,187944685)}),_vm._m(4),_c('router-link',{attrs:{"to":"/drivers/all"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-car"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Drivers")])])])]}}],null,false,3482194472)}),_vm._m(5),_c('router-link',{attrs:{"to":"/shipping/zone"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipping Zone")])])])]}}],null,false,1540071037)}),_c('router-link',{attrs:{"to":"/shipping/rate"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipping Rate")])])])]}}],null,false,2226134177)}),_c('router-link',{attrs:{"to":"/shipping/currency"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Currency Conversion")])])])]}}],null,false,1705983022)}),_c('router-link',{attrs:{"to":"/settings/vat"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("GST/Vat")])])])]}}],null,false,3022538013)}),_vm._m(6),_c('router-link',{attrs:{"to":"/settings/product-description"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Product Description")])])])]}}],null,false,3058742812)}),_c('router-link',{attrs:{"to":"/settings/weight-scale"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Weight Scale")])])])]}}],null,false,1256420937)}),_c('router-link',{attrs:{"to":"/settings/size-scale"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Size Scale")])])])]}}],null,false,3682224716)}),_c('router-link',{attrs:{"to":"/settings/currency"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Currency")])])])]}}],null,false,1336935062)}),_vm._m(7),_c('router-link',{attrs:{"to":"/settings/courier"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Couriers")])])])]}}],null,false,2412423543)}),_c('router-link',{attrs:{"to":"/settings/shipment-payer"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipment Payer")])])])]}}],null,false,1702746366)}),_c('router-link',{attrs:{"to":"/settings/shipping-mode"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipping Mode")])])])]}}],null,false,3897207552)}),_c('router-link',{attrs:{"to":"/settings/shipment-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipment Type")])])])]}}],null,false,3548621785)}),_c('router-link',{attrs:{"to":"/settings/shipment-status-list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shipment Status List")])])])]}}],null,false,1334842039)}),_c('router-link',{attrs:{"to":"/settings/remarks-status-list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Remarks List")])])])]}}],null,false,1339659810)}),_vm._m(8),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/settings') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(9),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(10),_c('router-link',{attrs:{"to":"/settings/branch"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Branch")])])])]}}],null,false,2022654853)}),_c('router-link',{attrs:{"to":"/settings/country"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Country")])])])]}}],null,false,3047790009)}),_c('router-link',{attrs:{"to":"/settings/state"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("States")])])])]}}],null,false,2552002293)}),_c('router-link',{attrs:{"to":"/settings/virtual-address"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Virtual Address")])])])]}}],null,false,1397889606)})],1)])])],1):_vm._e(),(_vm.currentUser.role == 6)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}],null,false,1129774971)}),_vm._m(11),_c('router-link',{attrs:{"to":"/prealert/book-shipment"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-location"}),_c('span',{staticClass:"menu-text"},[_vm._v("Book Shipment")])])])]}}],null,false,1204765762)}),_c('router-link',{attrs:{"to":"/prealert/shipments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cube"}),_c('span',{staticClass:"menu-text"},[_vm._v("My Shipments")])])])]}}],null,false,512721888)}),_c('router-link',{attrs:{"to":"/settings/address-book"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-map-location"}),_c('span',{staticClass:"menu-text"},[_vm._v("Addressbook")])])])]}}],null,false,641510919)}),_c('router-link',{attrs:{"to":"/shipping/search-rate"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-supermarket"}),_c('span',{staticClass:"menu-text"},[_vm._v("Shipping Rate")])])])]}}],null,false,849579571)})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Shipments")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Organization Staff")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Customers")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Containers")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Fleet Management")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Rates Management")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Products Settings")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Shipment Settings")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("General Settings")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-cogwheel"}),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Shipments")]),_c('i',{staticClass:"menu-icon ki ki-bold-more-hor icon-md"})])
}]

export { render, staticRenderFns }